import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 商家发货
const productDeliverGoods= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/order/deliverOrder', params)
}

// 商家订单列表
const productMerchantOrder = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/order/merchantOrder', {params})
}

// 商户的商品列表
const merchantProducts = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/merchant/products', {params})
}

// 商家信息
const merchantIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/merchant/index', {params})
}

// 商户列表
const merchantList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/merchant/list', {params})
}

// 编辑商户信息
const merchantUpdate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/merchant/update', params)
}

export default {
	productDeliverGoods,
	productMerchantOrder,
	merchantProducts,
	merchantIndex,
	merchantList,
	merchantUpdate
}

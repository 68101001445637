<template>
	<div class="MerchantDetail">
		<loading-page :loading="loadingPage"></loading-page>
		<w-navTab titleText="订单详情"></w-navTab>
		<div class="MerchantDetail-body">
			<div class="container-footer">
				<div class="container-footer-item">
					<div class="container-footer-item-left">收货姓名：</div>
					<div class="container-footer-item-right">{{address.real_name}}</div>
				</div>
				<div class="container-footer-item">
					<div class="container-footer-item-left">收货电话：</div>
					<div class="container-footer-item-right">{{address.telephone}}</div>
				</div>
				<div class="container-footer-item">
					<div class="container-footer-item-left">收货地址：</div>
					<div class="container-footer-item-right">
						{{address.full_address}}
					</div>
				</div>
			</div>
			<div class="container-goods" v-for="(item,index) in orderDetail.products">
				<div class="container-goods-item">
					<div class="container-goods-picture">
						<img :src="item.thumb" alt="">
					</div>
					<div class="container-goods-box">
						<div class="container-goods-box-title">{{item.title}}</div>
						<div class="container-goods-box-bottom">
							<div class="container-goods-box-bottom-number">
								数量：{{item.product_num}}
							</div>
						</div>
					</div>
				</div>
				<div class="container-goods-bottom-title-right">
					<div class="left-box">
						<div class="price-left">
							<div class="price-text">￥{{item.product_price}}</div>
							<div class="price-title">
								<span>￥</span>{{parseInt(item.product_price*100 - item.discount_amount*100)/parseInt(100).toFixed(2)}}
							</div>
						</div>
						<div class="text">积分抵扣<span>{{item.discount_amount}}</span></div>
					</div>
					<div class="right-button" v-if="item.status == 3"
						@click="$router.push({ name: 'MerchantShipments', params:{orderId: orderDetail.order_id, productId: item.product_id, merchantId: merchantId}})">
						去发货</div>
					<div class="right-button" v-if="item.status == 2" @click="onConfirm(item.product_id,index)">确认自提</div>
				</div>
				<div class="container-goods-bottom">
					<div class="bottom-box">
						<div class="bottom-box-item">
							<div class="bottom-box-item-left">订单状态：</div>
							<div class="bottom-box-item-right-status status-color1" v-if="item.status == 2">待自提</div>
							<div class="bottom-box-item-right-status status-color2" v-if="item.status == 3">待发货</div>
							<div class="bottom-box-item-right-status status-color3" v-if="item.status == 4">待收货</div>
							<div class="bottom-box-item-right-status status-color4" v-if="item.status == 5">已完成</div>
						</div>
						<template v-if="orderDetail.product_type == 1 && item.post_number != ''">
							<div class="bottom-box-item">
								<div class="bottom-box-item-left">快递公司：</div>
								<div class="bottom-box-item-right">
									{{item.post_company}}
								</div>
							</div>
							<div class="bottom-box-item">
								<div class="bottom-box-item-left">快递单号：</div>
								<div class="bottom-box-item-right">
									<div class="right-text">{{item.post_number}}</div>
									<div class="right-btn">
										<button :data-clipboard-text="item.post_number" @click="onCopy">复制</button>
									</div>
								</div>
							</div>
						</template>
					</div>
					
				</div>
			</div>
			<div class="container-footer">
				<div class="container-footer-item">
					<div class="container-footer-item-left">下单时间：</div>
					<div class="container-footer-item-right">
						{{pay.create_time}}
					</div>
				</div>
				<div class="container-footer-item">
					<div class="container-footer-item-left">订单编号：</div>
					<div class="container-footer-item-right">{{pay.order_number}}</div>
				</div>
				<div class="container-footer-item">
					<div class="container-footer-item-left">支付方式：</div>
					<div class="container-footer-item-right">
						{{pay.total_pay_amount}}元+{{pay.total_integral}}爱心积分
					</div>
				</div>
				<div class="container-footer-item">
					<div class="container-footer-item-left">商家名称：</div>
					<div class="container-footer-item-right">
						{{merchant.name}}
					</div>
				</div>
				<div class="container-footer-item">
					<div class="container-footer-item-left">商家地址：</div>
					<div class="container-footer-item-right">
						{{merchant.address}}
					</div>
				</div>
			</div>
			<!-- 用户留言 -->
			<div class="container-footer" v-if="orderDetail.remark != ''">
				<div class="message-title">用户留言：</div>
				<div class="message-body">
					{{orderDetail.remark}}
				</div>
			</div>
			<!-- 商家留言 -->
			<div class="container-footer" v-if="orderDetail.merchant_message != ''">
				<div class="message-title">商家留言：</div>
				<div class="message-body">
					{{orderDetail.merchant_message}}
				</div>
			</div>
			<div class="footer" v-if="orderDetail.product_type == 2">
				<div class="item-box">
					<div v-for="item in dataList" :key="item.id" class="itemDiv">
						<div id="qrcode" class="qrcode" ref="codeItem"></div>
					</div>
				</div>
				<div class="footer-body">
					<div class="body-top">
						<div class="top-img">
							<img src="@/assets/img/icon1001.png" alt="">
						</div>
						<div class="top-title">
							使用说明
						</div>
						<div class="top-img">
							<img src="@/assets/img/icon1002.png" alt="">
						</div>
					</div>
					<div class="body-box">
						<div class="body-text">
							1. 打开微信或支付宝“扫一扫”，扫描下方二维码；
						</div>
						<div class="body-text">
							2. 根据提示，填写用户信息;
						</div>
						<div class="body-text">
							3. 按步骤操作开通VIP；下载樊登读书APP或关注樊登读书小程序；
						</div>
						<div class="body-text">
							4. 进入樊登读书APP或小程序；登录后即可享受VIP权益.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VantVendor from '@/vendor/vant';
	import Commodity from '@/api/commodity';
	import Merchant from '@/api/merchant';
	import WTitle from '@/components/Title';
	import QRCode from 'qrcodejs2'
	import LoadingPage from '@/components/LoadingPage';
	export default {
		name: 'MerchantDetail',
		data() {
			return {
				orderId: '',
				orderDetail: '',
				address: '',
				pay: '',
				merchant: '',
				qrcode: null,
				dataList: [],
				loadingPage: true,
				merchantId: ''
			};
		},
		created() {
			this.orderId = this.$route.params.id
			this.merchantId = this.$route.params.merchantId
			this.getOrderDetail()
		},
		methods: {
			onCopy() {
				VantVendor.Toast.success('复制成功');
			},
			// 获取详情
			getOrderDetail() {
				Commodity.productOrderDetail({
						order_id: this.orderId
					})
					.then(result => {
						this.orderDetail = result.data
						this.address = result.data.address
						this.pay = result.data.pay
						this.merchantId = result.data.merchant.merchant_id
						this.dataList = this.orderDetail.coupon
						this.merchant = result.data.merchant
						this.bindQRCode();
						this.loadingPage = false
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			// 转换二维码
			bindQRCode() {
				this.$nextTick(() => {
					this.dataList.forEach((item, index) => {
						this.$refs.codeItem[index].innerHTML = "";
						var qrcode = new QRCode(this.$refs.codeItem[index], {
							text: item,
							width: 80,
							height: 80,
							render: 'table',
							colorDark: "#333333",
							colorLight: "#ffffff",
							correctLevel: QRCode.CorrectLevel.H
						})
						var childs = document.getElementsByClassName('qrcode')[index].childNodes;
						document.getElementsByClassName('qrcode')[index].removeChild(childs[0]);
					});
				}, 500)
			},
			// 确认自提
			onConfirm(id,index) {
				let params = {
					order_id: this.orderDetail.order_id,
					product_id: id,
				}
				VantVendor.Dialog.confirm({
						title: '提示',
						message: '您确认用户已经提货了吗？',
					})
					.then(() => {
						Merchant.productDeliverGoods(params)
							.then(result => {
								this.orderDetail.products[index].status = 5
								VantVendor.Toast.success('确认成功');
							})
							.catch(error => {
								VantVendor.Toast.fail(error.msg);
							});
					})
			}
		},
		components: {
			WTitle,
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.MerchantDetail {
		width: 100%;
		min-height: 100vh;
		background: #f2f2f2;

		.MerchantDetail-body {
			padding: 10px;
			padding-bottom: 20px;
			box-sizing: border-box;

			.container-footer:first-child {
				margin-top: 0;
			}

			.container-goods:first-child {
				margin-top: 0;
			}

			.container-goods {
				padding: 12px;
				background: #FFF;
				border-radius: 8px;
				margin-top: 10px;

				.container-goods-item:first-child {
					padding-top: 0;
				}

				.container-goods-item:last-child {
					border-bottom: 0;
				}

				.container-goods-item {
					padding: 12px 0;
					padding-bottom: 16px;
					border-bottom: 1px solid #F2F2F2;
					display: flex;

					.container-goods-picture {
						width: 80px;
						height: 80px;
						min-width: 80px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 6px;
						}
					}

					.container-goods-box {
						flex: 1;
						padding-left: 10px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.container-goods-box-title {
							font-size: 16px;
							line-height: 22px;
							color: #000;
						}

						.container-goods-box-bottom {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.container-goods-box-bottom-number {
								font-size: 14px;
								line-height: 22px;
								color: #777;
							}
						}
					}

				}

				.container-goods-bottom-title-right {
					padding: 8px 0;
					border-bottom: 1px solid #F2F2F2;
					display: flex;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;

					.left-box {
						.price-left {
							display: flex;
							align-items: center;

							.price-title {
								font-size: 16px;
								color: #151C39;

								span {
									font-size: 12px;
								}
							}

							.price-text {
								padding-right: 4px;
								font-size: 12px;
								line-height: 22px;
								text-decoration-line: line-through;
								color: #909399;
							}
						}

						.title {
							font-size: 16px;
							line-height: 16px;
							color: #151C39;
							margin-right: 5px;

							span {
								font-size: 16px;
								line-height: 16px;
							}
						}

						.text {
							font-size: 14px;
							line-height: 21px;
							color: #666;
							display: flex;
							align-items: center;

							span {
								display: block;
								display: flex;
								font-size: 14px;
								line-height: 17px;
								color: #151C39;

								&:before {
									content: '￥';
									font-size: 10px;
									line-height: 17px;
								}
							}
						}
					}

					.right-button {
						font-size: 16px;
						line-height: 26px;
						text-align: center;
						color: #FFF;
						padding: 4px 31px;
						box-sizing: border-box;
						background: #3377FF;
						border-radius: 100px;
					}
				}

				.container-goods-bottom {
					.bottom-box {
						margin-top: 10px;

						.bottom-box-item {
							margin-top: 10px;
						}

						.bottom-box-item {
							display: flex;
							justify-content: space-between;

							.bottom-box-item-left {
								font-size: 16px;
								line-height: 22px;
								color: #777;
								min-width: 80px;
								margin-right: 10px;
							}
							.bottom-box-item-right-status{
								font-size: 14px;
								line-height: 22px;
								text-align: center;
								padding: 0 6px;
								box-sizing: border-box;
								border-radius: 2px;
							}
							.status-color1{
								color: #FFB23F;
								background-color: rgba(255, 178, 63, 0.1);
							}
							.status-color2{
								color: #3377FF;
								background-color: rgba(51, 119, 255, 0.1);
							}
							.status-color3{
								color: #6ABB1B;
								background-color: rgba(106, 187, 27, 0.1);
							}
							.status-color4{
								color: #aaa;
								background-color: #F2F2F2;
							}
							.bottom-box-item-right {
								font-size: 16px;
								line-height: 22px;
								color: #151C39;
								display: flex;
								justify-content: end;
								.right-text {
									width: 160px;
									min-width: 160px;
									font-size: 16px;
									line-height: 22px;
									color: #151C39;
									text-align: right;
									margin-right: 8px;
									box-sizing: border-box;
									word-break: break-all;
								}

								.right-btn {
									button {
										border: 0;
										font-size: 14px;
										line-height: 22px;
										color: #3377FF;
										background: #F2F2F2;
										border-radius: 2px;
										box-sizing: border-box;
										flex-wrap: nowrap;
									}
								}

								span {
									display: inline-block;
									padding: 0 6px;
									font-size: 14px;
									line-height: 22px;
									color: #3377FF;
									background: #F2F2F2;
									border-radius: 2px;
									margin-left: 8px;
								}
							}
						}
					}
				}
			}

			.container-footer {
				background: #FFF;
				border-radius: 8px;
				margin-top: 10px;
				padding: 12px 10px;

				.container-footer-item:last-child {
					margin-bottom: 0;
				}

				.container-footer-item {
					display: flex;
					justify-content: space-between;
					margin-bottom: 16px;

					.container-footer-item-left {
						font-size: 16px;
						line-height: 22px;
						color: #777;
						min-width: 80px;
						margin-right: 10px;
					}

					.container-footer-item-right {
						font-size: 16px;
						line-height: 22px;
						color: #151C39;
						width: 210px;
						min-width: 210px;
						text-align: right;
						span {
							display: inline-block;
							padding: 0 6px;
							font-size: 14px;
							line-height: 22px;
							color: #3377FF;
							background: #F2F2F2;
							border-radius: 2px;
							margin-left: 8px;
						}

						button {
							border: 0;
							font-size: 14px;
							line-height: 22px;
							color: #3377FF;
							background: #F2F2F2;
							border-radius: 2px;
							margin-left: 8px;
						}
					}
				}

				.message-title {
					font-size: 16px;
					line-height: 22px;
					color: #777;
					margin-bottom: 10px;
				}

				.message-body {
					font-size: 16px;
					line-height: 22px;
					color: #151C39;
				}
			}

			.item-box {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;

				.itemDiv {
					margin: 10px;
				}
			}

			.footer {
				background: #FFF;
				border-radius: 8px;
				padding: 12px 10px;
				margin-top: 10px;

				.footer-body {
					padding: 0 10px;
					margin-top: 10px;

					.body-top {
						display: flex;
						justify-content: center;
						align-items: center;

						.top-img {
							width: 30px;
							height: 2px;

							img {
								width: 100%;
								height: 100%;
								display: block;
								object-fit: cover;
							}
						}

						.top-title {
							font-size: 16px;
							line-height: 24px;
							color: #4D4D4B;
							margin: 0 4px;
						}
					}

					.body-text {
						margin-top: 10px;
						font-size: 14px;
						line-height: 21px;
						color: #666;
					}
				}
			}
		}

	}
</style>